
import { defineComponent, createVNode } from "vue";
import { Input, Button, message, Card, Modal } from "ant-design-vue";
import {
  DragOutlined,
  EditOutlined,
  DeleteOutlined,
  RightOutlined,
  CheckOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons-vue";
import draggable from "vuedraggable";
import {
  addCategory,
  updateCategory,
  deleteCategory,
  sortCategory,
  searchCategory,
} from "@/api/terminalList.js";
export default defineComponent({
  components: {
    Button,
    Input,
    message,
    Card,
    draggable,
    DragOutlined,
    EditOutlined,
    DeleteOutlined,
    RightOutlined,
    CheckOutlined,
    SearchOutlined,
  },
  data() {
    return {
      height: "500px",
      editCategoryCode: -1,
      drag: false,
      newCategory: "",
      keepCategoryName: "",
      addNewCategory: false,
      activeCode: "",
      loading: false,
      contentHeight: "500px",
      // code: (this as any).$store.state.gradeCode3,
    };
  },
  computed: {},
  methods: {
    //点击新增品类
    addnew() {
      (this as any).$store.commit("changeCategoryEdit", true);
      this.addNewCategory = true;
      setTimeout(() => {
        let input: any = document.querySelector(".addnewInput");
        if (input) {
          input.focus();
        }
      }, 0);
    },
    //失去焦点 新增品类
    blurAddNewCategory() {
      if (this.newCategory.trim().length == 0) {
        this.addNewCategory = false;
        (this as any).$store.commit("changeCategoryEdit", false);
        return;
      }

      if (this.checkCategory(this.newCategory)) {
        return;
      }
      let sort = 1000 - (this as any).$store.state.gradeCode3.length;
      addCategory({
        categoryParCode: (this as any).$store.state.grade3,
        categoryName: this.newCategory,
        sort: sort,
      })
        .then((res) => {
          if (res.data.success) {
            this.newCategory=''
            message.success("新增成功");
            //第3栏重新获取数据
            (this as any).$store.commit(
              "changeGradeCode2",
              (this as any).$store.state.grade2
            );
            //自己重新获取数据
            (this as any).$store.commit(
              "changeGradeCode3",
              (this as any).$store.state.grade3
            );
            this.addNewCategory = false;
          } else {
            this.addNewCategory = false;
          }
          (this as any).$store.commit("changeCategoryEdit", false);
        })
        .catch(() => {
          // this.newCategory=''
          this.addNewCategory = false;
          (this as any).$store.commit("changeCategoryEdit", false);
        });
    },
    //获取afterSort
    getAfterSort(array) {
      array.forEach((item, index) => {
        item.afterSort = 1000 - index;
        item.beforeSort = 1000 - index;
      });
    },
    //校验
    checkCategory(str, index = -1) {
      let flag = false;
      str = str.trim();
      (this as any).$store.state.gradeCode3.forEach((ele: any, i) => {
        if (ele.categoryName === str.trim() && i !== index) {
          message.warning("当前品类名称已存在,不可重复使用");
          flag = true;
        }
      });
      if (str.length > 50) {
        message.warning("最多不能超过50个字符");
        flag = true;
      }
      if (str.length == 0) {
        message.warning("请输入品类名称");
        flag = true;
      }
      if (flag) {
        let input:any = document.querySelector(".editInput")||document.querySelector(".addnewInput");
        if (input) {
          input.focus();
        }
      }
      return flag;
    },
    //编辑
    edit(editCategoryCode, categoryName) {
      this.keepCategoryName = categoryName;
      this.editCategoryCode = editCategoryCode;
      setTimeout(() => {
        let input: any = document.querySelector(".editInput");
        if (input) {
          input.focus();
        }
      }, 0);
    },
    editCategroy(ele) {
      if (ele.categoryName == "") {
        (this as any).$store.commit(
          "changeGradeCode3",
          (this as any).$store.state.grade3
        );
        this.editCategoryCode = -1;
        return;
      }
      if (this.keepCategoryName != ele.categoryName) {
        // if (this.checkCategory(ele.categoryName, 1000 - ele.sort)) {
        //   return;
        // }
        updateCategory({
          categoryCode: ele.categoryCode,
          categoryName: ele.categoryName,
        }).then((res) => {
          if (res.data.success) {
            message.success("编辑品类成功");
            (this as any).$store.commit(
              "changeGradeCode3",
              (this as any).$store.state.grade3
            );
            this.editCategoryCode = -1;
          }
          // else {
          //   message.error(res.data.errMessage);
          // }
        });
      } else {
        this.editCategoryCode = -1;
      }
    },
    //删除
    ///////////////////////////////////////////////////////待修改
    del(categoryCode) {
      let that = this;
      Modal.confirm({
        title: "提示",
        icon: () => createVNode(ExclamationCircleOutlined),
        content: "是否确认删除?",
        cancelText: "取消",
        okText: "确认",
        onOk() {
          deleteCategory(categoryCode).then((res: any) => {
            if (res.data.success) {
              message.success("删除品类成功");
              (that as any).$store.commit(
                "changeGradeCode3",
                (that as any).$store.state.grade3
              );
              (that as any).$store.commit(
                "changeGradeCode2",
                (that as any).$store.state.grade2
              ); //通知第3栏重新获取数据
              (that as any).$store.commit("changeGradeCode4", "z"); //控制第5栏不显示
              (that as any).$store.state.gradeCode4 = []; //控制第5栏不显示
              that.editCategoryCode = -1;
            }
          });
        },
      });
    },
    //更改排序
    handleSort() {
      this.loading = true;
      let array: any = [];
      (this as any).$store.state.gradeCode3.forEach((el: any, i) => {
        el.sort = 1000 - i;
        array = [...array, { categoryCode: el.categoryCode, sort: el.sort }];
      });
      sortCategory({
        categoryParCode: (this as any).$store.state.grade3,
        sortCategory: array,
      }).then((res) => {
        if (res.data.success) {
          (this as any).$store.commit(
            "changeGradeCode3",
            (this as any).$store.state.grade3
          );
          message.success("排序成功");
        }
        // else {
        //   message.error("服务器开了个小差");
        // }
        this.loading = false;
      });
    },
    //点击
    active(code) {
      this.activeCode = code;
      (this as any).$store.commit("changeGradeCode4", code);
      (this as any).$store.state.grade4SearchInfo = "";
      // (this as any).$store.commit("changeGradeCode5", 'z');
    },
    //搜索功能
    search() {
      searchCategory(
        (this as any).$store.state.grade3SearchInfo,
        (this as any).$store.state.grade3
      ).then((res: any) => {
        res.data.data.forEach((element) => {
          element.beforeSort = element.sort;
          element.people = 4;
          element.subordinate = element.isHave;
        });
        (this as any).$store.state.grade4SearchInfo = "";
        (this as any).$store.state.gradeCode3 = res.data.data;
        (this as any).$store.state.gradeCode4 = [];
        (this as any).$store.commit("changeGradeCode4", "z");
      });
    },
  },
  watch: {
    editCategoryCode(value) {
      if (value != -1) {
        (this as any).$store.commit("changeCategoryEdit", true);
      } else {
        (this as any).$store.commit("changeCategoryEdit", false);
      }
    },
  },
  created() {
    this.height = document.body.clientHeight - 160 + "px";
    this.contentHeight = document.body.clientHeight - 288 + "px";
  },
   updated() {
    this.activeCode = "";
  },
});
